/* eslint-disable react/display-name */
import { useRouter } from 'next/router'
import { memo, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getPersistentReminderList, IPersistentReminder, updatePersistentReminderList } from 'shared/persistent'
import { eventStatusToInt, getMicrositeUniversalUrl, getSlotId, sendTrackEvent, simpleHash } from 'shared/utils'
import { micrositeLogin, micrositeReminder } from 'shared/webBridge'
import { RootState } from 'store'
import { useGetUserChanceQuery } from 'store/api.slice'
import { IEventConfigResponse } from 'types'
import styles from './ChanceBar.module.scss'
import RewardIcon from 'icons/RewardIcon'
import { useDialogs } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'

const ChanceBarContainer = ({ children }: { children?: React.ReactNode }) => (
  <div className={styles.container} id="chance-bar-container">
    <div className={styles.chanceBar} id="chance-bar">
      <div className={styles.rewardIconContainer}>
        <div className={styles.rewardIcon}>
          <RewardIcon />
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)

interface UpComingContentProps {
  beginTime: number
  endTime: number
  reminderTitle: string
  reminderContent: string
}

const UpComingContent = memo(({ beginTime, endTime, reminderTitle, reminderContent }: UpComingContentProps) => {
  const { query } = useRouter()
  const { t } = useTranslation()

  const reminderData = {
    redirectPath: getMicrositeUniversalUrl(query.site as string),
    title: reminderTitle,
    content: reminderContent,
    eventCode: query.cid,
  }

  const hash = useMemo(() => simpleHash(JSON.stringify(reminderData)), [])

  const [reminderList, setReminderList] = useState<IPersistentReminder[]>(() => {
    return getPersistentReminderList()
  })
  const [isReminded, setIsReminded] = useState(() => {
    return reminderList.some((reminder) => reminder.hash === hash)
  })

  const remindHandler = async () => {
    await micrositeReminder({
      ...reminderData,
      reminderId: beginTime,
      startTime: Math.round(beginTime / 1000),
      endTime: Math.round(endTime / 1000),
    })
    //* Only dispatch addReminder if it's in Shopee App or in IFrame Test
    if (!document.referrer || document.referrer.startsWith(process.env.BASE_URL as string)) {
      setIsReminded(true)
      const newReminderList = [...reminderList, { beginTime: beginTime, hash }]
      setReminderList(newReminderList)
      updatePersistentReminderList(newReminderList)
    }
  }

  return (
    <ChanceBarContainer>
      <p>{t('Sự kiện sắp diễn ra.')}</p>
      <button className={styles.actionBtn} onClick={remindHandler} disabled={isReminded}>
        {isReminded ? t('Đã bật nhắc nhở') : t('Bật nhắc nhở')}
      </button>
    </ChanceBarContainer>
  )
})

const LoginContent = memo(() => {
  const { query } = useRouter()
  const { t } = useTranslation()
  return (
    <ChanceBarContainer>
      <p>{t('Vui lòng đăng nhập.')}</p>
      <button
        className={styles.actionBtn}
        onClick={() => {
          micrositeLogin(query.site as string)
        }}
      >
        {t('Đăng nhập ngay')}
      </button>
    </ChanceBarContainer>
  )
})

const GetChanceContent = memo(() => {
  const { t } = useTranslation()
  const {
    query: { cid },
  } = useRouter()
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)

  const { data: chancesData } = useGetUserChanceQuery({
    eventCode: cid as string,
  })

  const { open: openTaskDialog } = useDialogs(DialogType.TaskDialog)

  const handleGetMoreChanceClick = () => {
    const info = {
      operation: 'click',
      page_section: 'get_more_chances_button',
      data: {
        game_activity_id: '0',
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
      },
    }
    // console.log('track event #4:', info)
    sendTrackEvent(info)

    openTaskDialog()
  }

  return (
    <ChanceBarContainer>
      <p>
        <Trans count={chancesData?.total_balance ?? 0}>
          Bạn còn <b>{'{{ count }}'}</b> lượt mở.
        </Trans>
      </p>
      <button className={styles.actionBtn} onClick={handleGetMoreChanceClick}>
        {t('Thêm lượt')}
      </button>
    </ChanceBarContainer>
  )
})

const ChanceBar = ({ data }: { data?: IEventConfigResponse }) => {
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const { t } = useTranslation()

  if (!data) return <ChanceBarContainer />

  if (eventStatus === 'up-coming') {
    return (
      <UpComingContent
        beginTime={data.begin_time}
        endTime={data.end_time}
        reminderTitle={data.text_reminder_title}
        reminderContent={data.text_reminder_content}
      />
    )
  }

  if (eventStatus === 'on-going') {
    return isLogin ? <GetChanceContent /> : <LoginContent />
  }

  return (
    <ChanceBarContainer>
      <p className="mr-4">{t('Sự kiện đã kết thúc. Hẹn bạn dịp sau!')}</p>
    </ChanceBarContainer>
  )
}

export default memo(ChanceBar)
