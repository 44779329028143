import { showToast, TaskDialog, ToastContainer } from '@spvn/alpha-component-library'
import BoxGrid from 'components/BoxGrid'
import ChanceBar from 'components/ChanceBar'
import ErrorPopup from 'components/ErrorPopup'
import InfoBar from 'components/InfoBar'
import MilestoneZone from 'components/MilestoneZone'
import ClaimPrizeDialog from 'dialogs/ClaimPrizeDialog'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IAxiosBaseQueryError } from 'shared/axios-settings'
import {
  useDialogs,
  useEventStatusCalculation,
  useGetConfigErrorHanlder,
  useImpressionTaskDialogTracking,
  usePollingConfig,
  useQueryParamCheck,
  useServerTime,
  useViewOfGameTracking,
} from 'shared/hook'
import { getPersistentDialog, removePersistentDialog } from 'shared/persistent'
import {
  executeTask,
  getCFImage,
  handleShareNavigation,
  imageLoader,
  isLargeScreen,
  trackingViewMoreTaskDialogAction,
  trackingCloseTaskDialogAction,
} from 'shared/utils'
import { useGetEventConfigQuery, useGetUserChanceQuery } from 'store/api.slice'
import { DialogType } from 'store/dialogs.slice'
import { useGetTasksQuery } from 'store/task-api.slice'

const Home: NextPage = () => {
  const router = useRouter()
  const { query } = router
  const { t } = useTranslation()
  const { data, error } = useGetEventConfigQuery(query.cid as string, {
    skip: !query.cid,
    refetchOnFocus: true,
  })
  const appRef = useRef(null)
  const { eventStatus } = useEventStatusCalculation(data)

  const { isOpen: isTaskDialogOpen, close: closeTaskDialog, open: openTaskDialog } = useDialogs(DialogType.TaskDialog)

  const {
    data: tasksData,
    error: tasksError,
    refetch: refetchTasks,
  } = useGetTasksQuery(
    { eventCode: query.cid as string },
    {
      skip: !query.cid || !isTaskDialogOpen,
      // refetchOnFocus: true, //
    },
  )

  const { data: chancesData, refetch: refetchUserChance } = useGetUserChanceQuery(
    {
      eventCode: query.cid as string,
    },
    { skip: !query.cid || eventStatus !== 'on-going', refetchOnFocus: true },
  )

  useViewOfGameTracking(appRef)
  useQueryParamCheck()
  usePollingConfig()
  useServerTime()
  useGetConfigErrorHanlder(error as IAxiosBaseQueryError)
  const { setCurrentView } = useImpressionTaskDialogTracking({ isTaskDialogOpen, chancesData, tasksData })

  useEffect(() => {
    const visibilityControl = () => {
      if (document.visibilityState === 'visible' && isTaskDialogOpen) {
        refetchTasks()
      }
    }
    document.addEventListener('visibilitychange', visibilityControl)

    return () => document.removeEventListener('visibilitychange', visibilityControl)
  }, [])

  useEffect(() => {
    if (tasksData?.prize_awarded) {
      refetchUserChance()
      const totalPrize = tasksData.prize_awarded.find((prize) => prize.type === 'prize_chance')?.quantity || 0
      showToast({ message: t('Bạn đã nhận {{count}} lượt mở', { count: totalPrize }), iconType: 'success' })
      removePersistentDialog()
    } else if (tasksError) {
      showToast({ message: t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
      removePersistentDialog()
    }
  }, [tasksData, tasksError])

  useEffect(() => {
    if (!query.cid) return
    if (!getPersistentDialog()) return
    if (getPersistentDialog().dialog === DialogType.TaskDialog) {
      openTaskDialog()
      if (getPersistentDialog().willRemovePersistentOnOpen) {
        removePersistentDialog()
      }
    }
  }, [query])

  return (
    <main
      ref={appRef}
      className="h-screen"
      style={{
        backgroundImage: `url(${
          data
            ? imageLoader({
                src: getCFImage({
                  url: isLargeScreen() ? data?.image_background_pc : data?.image_background_mobile,
                  isHash: true,
                }),
                width: isLargeScreen() ? 1920 : 375,
              })
            : ''
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <ChanceBar data={data} />
      <InfoBar data={data} />
      <BoxGrid data={data} remainingChances={chancesData?.total_balance} />
      <MilestoneZone data={data} />
      <ClaimPrizeDialog />
      {eventStatus === 'on-going' && (
        <TaskDialog
          open={isTaskDialogOpen}
          tasks={tasksData?.tasks}
          userChance={chancesData}
          onShareTask={() => handleShareNavigation('event', !chancesData?.remaining_share_times ? 1 : 0)}
          onExecuteTask={executeTask}
          chanceDesc={t('Bạn còn {{count}} lượt mở')}
          taskChanceDesc={t('Nhận {{count}} lượt mở')}
          shareTaskTitle={t('Chia sẻ để thêm lượt')}
          primaryActionText={t('Xem nhiệm vụ khác')}
          isShowShareTask={true}
          primaryActionCallback={(nextView) => {
            trackingViewMoreTaskDialogAction()
            setCurrentView(nextView)
          }}
          onCloseDialog={() => {
            removePersistentDialog()
            trackingCloseTaskDialogAction()
            closeTaskDialog()
          }}
        />
      )}
      <ErrorPopup />
      <ToastContainer />
    </main>
  )
}

export default memo(Home)
