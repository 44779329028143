import 'animate.css'
import Image from 'next/image'
import { memo, useEffect, useMemo, useState, useRef } from 'react'
import { IEventConfigResponse } from 'types'
import { eventStatusToInt, getCFImage, getSizeByBoxesNumber, getSlotId, sendTrackEvent } from 'shared/utils'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import clsx from 'clsx'
import { useTrackingImpression } from 'shared/hook'

interface MysteryBoxProps {
  data: IEventConfigResponse
  boxIdx: number
  onOpen: () => void
  onImpress: (boxIdx: number, isOpen: 0 | 1) => void
  outImpress: (boxIdx: number) => void
}

//TODO: should refactor this, this component should depend to much on logic state
const MysteryBox = ({ data, boxIdx, onOpen, onImpress, outImpress }: MysteryBoxProps) => {
  const boxIdxOpened = useSelector((state: RootState) => state.app.boxIdxOpened)
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const [isOpen, setIsOpen] = useState(false)
  const boxRef = useRef(null)

  const boxDimen = useMemo(() => {
    if (window.innerWidth <= 280) {
      return Math.min(82, (window.innerWidth - 8 * 4) / 3)
    } else if (window.innerWidth < 960) {
      return Math.min(112, (window.innerWidth - 8 * 4) / 3)
    } else {
      return 200
    }
    if (window.innerWidth < 960) {
      // return {
      //   large: 253,
      //   medium: 164,
      //   small: 105,
      //   extraSmall: 82,
      // }
      return 112
    }

    // return {
    //   large: 400,
    //   medium: 250,
    //   small: 250,
    // }
  }, [])

  const { isImpressed } = useTrackingImpression({
    ref: boxRef,
    inAction: () => onImpress(boxIdx, isOpen ? 1 : 0),
    outAction: () => outImpress(boxIdx),
  })

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (boxIdxOpened === null && isOpen) {
      setIsOpen(false)
      if (isImpressed) onImpress(boxIdx, 0)
    }

    if (boxIdxOpened === boxIdx) {
      timer = setTimeout(() => {
        setIsOpen(true)
        if (isImpressed) onImpress(boxIdx, 1)
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [boxIdxOpened])

  const assets = useMemo(() => {
    const res = {
      // size: getSizeByBoxesNumber(data.number_of_boxes),
      img: data.image_upcoming,
      textColor: data.text_color_upcoming,
      buttonColor: data.color_upcoming_button,
      openedImg: data.image_opened,
      openedTextColor: data.text_color_opened,
      openedButtonColor: data.color_opened_button,
    }

    if (eventStatus === 'on-going') {
      res.img = isOpen ? data.image_opened : data.image_ongoing
      res.textColor = isOpen ? data.text_color_opened : data.text_color_ongoing
      res.buttonColor = isOpen ? data.color_opened_button : data.color_ongoing_button
    }

    if (eventStatus === 'ended') {
      res.img = data.image_expired
      res.textColor = data.text_color_expired
      res.buttonColor = data.color_expired_button
    }

    return res
  }, [data, isOpen, eventStatus])

  const handleBoxOpen = () => {
    const info = {
      operation: 'click',
      page_section: 'box_button_component',
      data: {
        game_activity_id: '0',
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
        box_is_open: +isOpen,
        box_position: boxIdx + 1,
      },
    }
    // console.log('track event #3:', info)
    sendTrackEvent(info)

    onOpen()
  }

  return (
    <button
      disabled={boxIdxOpened !== null || eventStatus !== 'on-going'}
      className="flex flex-col relative items-center first:ml-0 last:mr-0 mx-1 smx:mx-1 large:mx-3 max-w-[112px] large:max-w-[200px]"
      onClick={handleBoxOpen}
      ref={boxRef}
    >
      {/* <div style={{ width: boxDimen[assets.size], height: boxDimen[assets.size] }}> */}
      <Image
        src={getCFImage({ url: assets.img, isHash: true })}
        width={boxDimen}
        height={boxDimen}
        alt=""
        draggable={false}
        className={clsx({ 'animate__animated animate__tada animate__slow': boxIdxOpened === boxIdx })}
      />
      {/* </div> */}

      <div
        style={{
          color: assets.textColor.color,
          backgroundColor: assets.buttonColor,
        }}
        className={clsx(
          'flex items-center justify-center mt-4 large:mt-5 text-white bg-primary rounded h-7 large:h-11 text-normal large:text-large smx:text-small max-w-[200px] w-full',
        )}
      >
        {assets.textColor.text}
      </div>
    </button>
  )
}

// const areEqual = (prevProps: MysteryBoxProps, nextProps: MysteryBoxProps) => {
//   return prevProps.disabled === nextProps.disabled && prevProps.isOpen === nextProps.isOpen
// }

export default memo(MysteryBox)
