import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralDialog } from '@spvn/alpha-component-library'
import Image from 'next/image'
import { eventStatusToInt, getCFImage, getSlotId, requestTimeout, sendTrackEvent } from 'shared/utils'
import { useDialogs, useGetConfigErrorHanlder } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'
import { IOpenBoxResponse, ITimer } from 'types'
import { RootState, useAppDispatch } from 'store'
import { setBoxIdxOpened } from 'store/app.slice'
import { micrositeNavigate } from 'shared/webBridge'
import { useSelector } from 'react-redux'
import { useGetEventConfigQuery } from 'store/api.slice'
import { useRouter } from 'next/router'
import { IAxiosBaseQueryError } from 'shared/axios-settings'

const ClaimPrizeDialog = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { query } = router

  const { isOpen, close, prize = {} } = useDialogs(DialogType.ClaimPrizeDialog)
  const [isOpenProtectLayer, setOpenProtectLayer] = useState(isOpen)

  useEffect(() => {
    let timer: ITimer
    if (isOpen) {
      setOpenProtectLayer(true)
      timer = requestTimeout(() => {
        setOpenProtectLayer(false)
      }, 1000) //Handle user multiclick
    }
    return () => {
      timer?.clear()
    }
  }, [isOpen])

  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const { text_prize_popup_desc, text_prize_popup_title, image_prize_icon, cta_text, cta_type, cta_url, prize_type } =
    prize as IOpenBoxResponse['prize']

  const { data, error } = useGetEventConfigQuery(query.cid as string, {
    skip: !query.cid,
    refetchOnFocus: true,
  })

  const clickPrimaryButtonRef = useRef(false)

  useGetConfigErrorHanlder(error as IAxiosBaseQueryError)

  const handleCloseDialog = () => {
    const info = {
      operation: 'click',
      page_section: 'congratulation_popup_close_button',
      data: {
        game_activity_id: '0',
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
      },
    }
    // console.log('track event #5:', info)
    sendTrackEvent(info)
    dispatch(setBoxIdxOpened(null))
    clickPrimaryButtonRef.current = false
    close()
  }

  const handleOnClickPrimary = async () => {
    if (clickPrimaryButtonRef.current) return

    clickPrimaryButtonRef.current = true
    const info = {
      operation: 'click',
      page_section: 'congratulation_popup_check_button',
      data: {
        game_activity_id: '0',
        game_slot_id: `${getSlotId()}`,
        status: eventStatusToInt(eventStatus),
      },
    }
    // console.log('track event #6:', info)
    await sendTrackEvent(info, { immediate: true })

    if (cta_type === 'c_cta_click_to_close') {
      handleCloseDialog()
    } else {
      micrositeNavigate(cta_url)
      dispatch(setBoxIdxOpened(null))
      clickPrimaryButtonRef.current = false
      close()
    }
  }

  return (
    <>
      <GeneralDialog
        onClose={handleCloseDialog}
        open={isOpen}
        primaryActionText={cta_text}
        primaryActionProps={{
          style: { color: data?.color_cta_text ?? 'black', backgroundColor: data?.color_cta_button ?? 'white' },
        }}
        secondaryActionText={t('Mở tiếp')}
        hideSecondaryAction={prize_type === 'NONE'}
        onClickPrimary={handleOnClickPrimary}
        backdropClickable={true}
      >
        <div className="p-2.5 large:p-5 flex justify-center items-center flex-col text-center text-normal large:text-title large:w-[470px]">
          <div className="relative h-[125px] large:h-[160px] w-[125px] large:w-[160px] mb-2.5 large:mb-5">
            {image_prize_icon && (
              <Image src={getCFImage({ url: image_prize_icon, isHash: true })} alt="" layout="fill" />
            )}
          </div>
          <p className="font-bold mb-1 large:mb-2">{text_prize_popup_title}</p>
          <p className="mb-1 large:mb-0 max-w-[350px] large:max-w-[410px] whitespace-pre-line leading-5 large:leading-7.5">
            {text_prize_popup_desc}
          </p>
        </div>
      </GeneralDialog>
      {isOpen && isOpenProtectLayer && <div className="absolute top-0 left-0 h-full w-full bg-transparent"></div>}
    </>
  )
}

export default memo(ClaimPrizeDialog)
