import MysteryBox from 'components/MysteryBox'
import { useRouter } from 'next/router'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useImpressionBoxTracking } from 'shared/hook'
import { micrositeLogin } from 'shared/webBridge'
import { RootState, useAppDispatch } from 'store'
import { useOpenBoxMutation } from 'store/api.slice'
import { DialogType, openDialog } from 'store/dialogs.slice'
import { IEventConfigResponse } from 'types'

const BoxGrid = ({ data, remainingChances }: { data?: IEventConfigResponse; remainingChances?: number }) => {
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const {
    query: { cid, site },
  } = useRouter()
  const [openBox] = useOpenBoxMutation()
  const dispatch = useAppDispatch()

  const handleOpenBox = (boxIdx: number) => {
    if (!isLogin) micrositeLogin(site as string)
    else if (remainingChances === 0) dispatch(openDialog({ dialogType: DialogType.TaskDialog }))
    else openBox({ boxNo: boxIdx + 1, eventCode: cid as string })
  }

  const { onBoxImpression, onBoxOutImpression } = useImpressionBoxTracking()

  const numberOfBoxes = useMemo(() => {
    return data?.number_of_boxes ?? 0
  }, [data])

  return (
    <>
      {data &&
        Array.from(Array(numberOfBoxes <= 3 ? 1 : 2).keys()).map((rowIdx) => {
          const boxesArr = Array.from(Array(data?.number_of_boxes).keys())
          const breakIdx = numberOfBoxes > 3 ? Math.ceil(data?.number_of_boxes / 2) : numberOfBoxes
          const rowBoxes = rowIdx === 0 ? boxesArr.slice(0, breakIdx) : boxesArr.slice(breakIdx)

          return (
            <div key={rowIdx} className="flex justify-center mx-4 smx:mx-2 mb-2 large:mb-5">
              {rowBoxes.map((boxIdx) => (
                <MysteryBox
                  key={boxIdx}
                  boxIdx={boxIdx}
                  data={data}
                  onOpen={() => handleOpenBox(boxIdx)}
                  onImpress={onBoxImpression}
                  outImpress={onBoxOutImpression}
                />
              ))}
            </div>
          )
        })}
    </>
  )
}

export default memo(BoxGrid)
