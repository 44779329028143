import clsx from 'clsx'
import ArrowRightIcon from 'icons/ArrowRight'
import { useRouter } from 'next/router'
import { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isLargeScreen } from 'shared/utils'
import { RootState } from 'store'
import { useGetMilestoneUserStatusQuery, useGetNumberOfOpenedBoxQuery } from 'store/api.slice'
import { IEventConfigResponse, IMilestoneUserStatusResponse } from 'types'
import HintBar from './HintBar'
import MilestoneBox from './MilestoneBox'

interface MilestoneZoneProps {
  data?: IEventConfigResponse
}

const BOX_WIDTH = isLargeScreen() ? 44 : 32

const MilestoneZone: React.FC<MilestoneZoneProps> = ({ data }) => {
  const { query, isReady } = useRouter()
  const eventStatus = useSelector((state: RootState) => state.app.eventStatus)
  const isUseMilestone = data?.milestone_setting.radio_apply_milestone_setting === 'yes'

  const { data: dataNumberOpenedBoxes } = useGetNumberOfOpenedBoxQuery(
    { eventCode: query.cid as string },
    { skip: !isUseMilestone || !isReady || !query.cid || !(eventStatus === 'on-going' || eventStatus === 'ended') },
  )

  const { data: dataMilestoneUserStatus } = useGetMilestoneUserStatusQuery(
    { eventCode: query.cid as string },
    { skip: !isUseMilestone || !isReady || !query.cid || !(eventStatus === 'on-going' || eventStatus === 'ended') },
  )

  const milestonesData: IMilestoneUserStatusResponse['milestones'] = useMemo(() => {
    if (eventStatus === 'on-going' || eventStatus === 'ended') {
      return dataMilestoneUserStatus?.milestones ?? []
    }
    return (
      data?.milestones?.map((ele) => {
        return {
          milestone_name: ele.text_milestone_name,
          target_achievement: ele.number_target_achievement_milestone,
          status: 'INCOMPLETE',
          milestone_id: -1,
        }
      }) ?? []
    )
  }, [eventStatus, dataMilestoneUserStatus, data, data?.milestones])

  const [progressBarWidth, setProgressbarWidth] = useState(0)
  const [translateValue, setTranslateValue] = useState(0)
  const [percentageBarWidth, setPercentageBarWidth] = useState(0)

  const maxBoxesPerScreen = useMemo(() => {
    if (!milestonesData || !milestonesData.length) return -1
    return Math.min(milestonesData.length, 4)
  }, [milestonesData])

  // useEffect(() => {
  //   console.log({ maxBoxesPerScreen }, 'milestoneData')
  // }, [maxBoxesPerScreen])

  useEffect(() => {
    //only calculate when there is data to render!
    if (!data || !data.milestone_setting || !isUseMilestone) return
    setProgressbarWidth(
      +window
        .getComputedStyle(document.getElementById('spvn-mb-progress-container') as HTMLElement, null)
        .width.split('px')[0],
    )
  }, [data, data?.milestone_setting])

  useEffect(() => {
    if (!dataNumberOpenedBoxes || milestonesData.length === 0) return

    const isReachedAllTarget =
      milestonesData[milestonesData.length - 1].target_achievement <= dataNumberOpenedBoxes.number_opened_boxes

    // TASK: increase percentage bar due to number of boxes opened
    const divisionSpace = progressBarWidth / maxBoxesPerScreen
    let nextBoxIdx = isReachedAllTarget
      ? milestonesData.length - 1
      : milestonesData.findIndex((ele) => {
          return ele.target_achievement >= dataNumberOpenedBoxes.number_opened_boxes
        })

    // Handle case: 2 or more same box, increase progress bar to the last box, only if the first box target is hit
    if (milestonesData[nextBoxIdx].target_achievement === dataNumberOpenedBoxes.number_opened_boxes) {
      const tempNextBox = milestonesData[nextBoxIdx]

      milestonesData.forEach((ele, idx) => {
        if (ele.target_achievement === tempNextBox.target_achievement && idx > nextBoxIdx) {
          nextBoxIdx = idx
        }
      })
    }

    const curBoxIdx = nextBoxIdx - 1
    const preWidth = divisionSpace * (curBoxIdx + 1) //Since idx of boxes starts from 0 --> Add 1 for correct calculation
    const scalingFactor = (BOX_WIDTH / 2) * 1.4
    if (nextBoxIdx === 0) {
      const addOnWidth =
        ((divisionSpace - scalingFactor) / milestonesData[nextBoxIdx].target_achievement) *
        dataNumberOpenedBoxes.number_opened_boxes
      // console.log({ nextBoxIdx, curBoxIdx, preWidth, addOnWidth, progressBarWidth, divisionSpace }, 'case1')
      setPercentageBarWidth(preWidth + addOnWidth)
    } else {
      if (milestonesData[nextBoxIdx].target_achievement === milestonesData[curBoxIdx].target_achievement) {
        const addOnWidth = divisionSpace - scalingFactor
        setPercentageBarWidth(preWidth + addOnWidth)
      } else {
        const addOnWidth =
          ((divisionSpace - scalingFactor) /
            (milestonesData[nextBoxIdx].target_achievement - milestonesData[curBoxIdx].target_achievement)) *
          (Math.min(
            dataNumberOpenedBoxes.number_opened_boxes,
            milestonesData[milestonesData.length - 1].target_achievement,
          ) -
            milestonesData[curBoxIdx].target_achievement)
        // console.log({ nextBoxIdx, curBoxIdx, preWidth, addOnWidth, progressBarWidth, divisionSpace }, 'case2')
        setPercentageBarWidth(preWidth + addOnWidth)
      }
    }

    // TASK: Update position of last rewarded milestone
    const latestReachedAndReceivedIdx =
      milestonesData.filter((ele) => ele.status === 'COMPLETED' || ele.status === 'REWARDED').length - 1
    if (latestReachedAndReceivedIdx >= 3) {
      const latestMilestoneDOM = document.getElementById(`spvn-milestone-box-no-${latestReachedAndReceivedIdx}`)

      const matrix = new DOMMatrixReadOnly(latestMilestoneDOM?.style.transform)
      const latestTransformX = matrix.m41
      setTranslateValue(
        (cur) =>
          cur + (latestTransformX - progressBarWidth + BOX_WIDTH + (isReachedAllTarget ? 0 : progressBarWidth / 4)),
      )
    }
  }, [dataNumberOpenedBoxes, milestonesData])

  const isInfoBarEmpty = eventStatus === 'ended' && data?.reminder_next_game_setting.radio_reminder_next_game === 'no'

  if (!data || !data.milestone_setting || !isUseMilestone) return null

  return (
    <div
      id="spvn-mb-milestone-zone"
      className={clsx(
        'bg-white relative flex flex-col items-center rounded-lg border border-solid mt-8 mx-3 large:max-w-[449px] large:mx-auto large:mt-[56px]',
        isInfoBarEmpty && 'mt-[58px] large:mt-[80px]',
      )}
      style={{ borderColor: data.milestone_setting.color_milestone_border }}
    >
      <div
        className="bg-white rounded-[20px] border border-solid absolute top-0 -translate-y-1/2 px-4 py-1 large:px-5 large:py-1.5"
        style={{ borderColor: data.milestone_setting.color_milestone_border }}
      >
        <p
          className="text-small font-bold large:text-large"
          style={{ color: data.milestone_setting.text_color_milestone_header.color }}
        >
          {data.milestone_setting.text_color_milestone_header.text}
        </p>
      </div>
      <div
        id="spvn-mb-progress-zone"
        className="mt-2.5 mb-3 large:mt-4 large:mb-4.5 w-full px-[20px] relative large:px-[10px]"
      >
        <div className="pr-[14px] ml-[14px] large:pr-[10px] large:ml-[10px] relative h-[66px] large:h-[90px] overflow-hidden flex items-center">
          <div id="spvn-mb-progress-container" className="rounded-[10px] h-[10px] w-full overflow-hidden">
            <div
              id="spvn-mb-progress-full"
              className="h-full bg-[#F5F5F5] relative transition-transform motion-reduce:transition-none"
              style={{
                transform: `translateX(-${translateValue}px)`,
                width: (progressBarWidth / maxBoxesPerScreen) * milestonesData.length,
              }}
            >
              <div
                id="spvn-mb-progress-percentage"
                className="h-full flex justify-end items-center rounded-[10px] pr-0.5 transition-width"
                style={{
                  backgroundColor: data.milestone_setting.color_progress_bar_milestone,
                  width: (dataNumberOpenedBoxes?.number_opened_boxes ?? -1) <= 0 ? 10 : percentageBarWidth,
                }}
              >
                <div id="spvn-mb-progress-indicator" className="rounded-full w-[6px] h-[6px] bg-white"></div>
              </div>
            </div>
          </div>
          {milestonesData.map((ele, idx) => {
            return (
              <MilestoneBox
                title={ele.milestone_name}
                translateX={(progressBarWidth / maxBoxesPerScreen) * (idx + 1) - BOX_WIDTH - translateValue}
                key={idx}
                isReached={ele.status === 'COMPLETED'}
                isReceived={ele.status === 'REWARDED'}
                boxImgComplete={data.milestone_setting.image_completed_milestone}
                boxImgIncomplete={data.milestone_setting.image_incomplete_milestone}
                boxImgReceive={data.milestone_setting.image_received_milestone_reward}
                boxWidth={BOX_WIDTH}
                milestoneId={ele.milestone_id}
                pos={idx}
              />
            )
          })}
        </div>
        {translateValue > 0 && (
          <div
            className="rotate-180 inline-block absolute left-3.5 large:left-2 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => {
              setTranslateValue((totalTranslatedValue) => {
                if (totalTranslatedValue <= progressBarWidth + (progressBarWidth / maxBoxesPerScreen - BOX_WIDTH) / 2) {
                  return 0
                } else {
                  return (
                    totalTranslatedValue -
                    progressBarWidth +
                    (totalTranslatedValue + progressBarWidth ===
                    (progressBarWidth / maxBoxesPerScreen) * milestonesData.length
                      ? (progressBarWidth / maxBoxesPerScreen - BOX_WIDTH) / 2
                      : 0)
                  )
                }
              })
            }}
          >
            <ArrowRightIcon color={data.milestone_setting.color_progress_bar_milestone} />
          </div>
        )}
        {(progressBarWidth / maxBoxesPerScreen) * milestonesData.length - (translateValue + progressBarWidth) > 0 && (
          <div
            className="inline-block absolute right-3.5 large:right-2 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => {
              setTranslateValue((totalTranslatedValue) => {
                const totalWidthOfMilestoneLeft =
                  (progressBarWidth / maxBoxesPerScreen) * milestonesData.length -
                  (totalTranslatedValue + progressBarWidth)
                if (totalWidthOfMilestoneLeft <= progressBarWidth) {
                  return totalTranslatedValue + totalWidthOfMilestoneLeft
                } else {
                  return (
                    totalTranslatedValue +
                    progressBarWidth +
                    (totalTranslatedValue > 0 ? 0 : (progressBarWidth / maxBoxesPerScreen - BOX_WIDTH) / 2)
                  )
                }
              })
            }}
          >
            <ArrowRightIcon color={data.milestone_setting.color_progress_bar_milestone} />
          </div>
        )}
      </div>
      <div className="text-small border-t-[1px] border-t-[#F5F5F5] py-3.5 large:py-3 w-full flex justify-center">
        <HintBar
          milestoneSetting={data.milestone_setting}
          milestoneData={milestonesData}
          numOpenedBoxes={dataNumberOpenedBoxes?.number_opened_boxes ?? 0}
        />
      </div>
    </div>
  )
}

export default memo(MilestoneZone)
